import axios from "axios";
import { baseAPI } from "./baseAPI";

const initialState = { isFetching: false, error: false };

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "login.isFetching":
      return { ...state, isFetching: true, error: false};

    case "login.fetchSuccess":
      return { ...state, isFetching: false, error: false };

    case "login.fetchError":
      return { ...state, isFetching: false, error: action.error};

    default:
      return state;
  }
}

// Actions
export function submitLogin({ email, password }, ownProps) {
  return async (dispatch) => {
    dispatch({ type: "login.isFetching" });

    try {
      const response = await axios.post(`${baseAPI}/auth/login`, {
        username: email,
        password,
      });

      dispatch({
        type: "login.fetchSuccess",
      });

      localStorage.setItem("accessToken", response.data.accessToken);
      window.location.replace("/collector");
    } catch (error) {    
      dispatch({ type: "login.fetchError", error: {message : "Invalid username or password"} });
    }
  };
}

export function cleanAuthError(){
  return dispatch => {
    dispatch({
      type: "login.fetchSuccess",
    });
  }
}
