// Libs
import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useQueryParams, StringParam } from "use-query-params";

// Components
import { Input, Button } from "../../designSystem";

// Actions
import { setHistory, removeAllHistory } from "../../module/UI/search";

// Style
import "./Search.scss";
const Search = ({ onClick, value, setHistory, removeAllHistory }) => {
  const [searchValue, setSearchValue] = useState("");
  // eslint-disable-next-line
  const [query, setQuery] = useQueryParams({
    search: StringParam,
  });

  const onKeyDown = e => {
    if (e.key === "Enter") {
      onClick(searchValue);
    }
  };

  return (
    <div className="Search__container">
      <Input
        theme="secondary"
        type="text"
        value={value}
        placeholder="Search"
        onChange={e => setSearchValue(e.target.value)}
        onKeyDown={e => onKeyDown(e)}
        required
      />
      <Button
        onClick={() => {
          onClick(searchValue);
          setHistory({ historyValue: window.location.search });
        }}
      >
        Search
      </Button>
      <Button
        theme="secondary"
        onClick={() => {
          removeAllHistory();
          setQuery({ search: "" });
        }}
      >
        Reset All
      </Button>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setHistory, removeAllHistory }, dispatch);
}

export default connect(null, mapDispatchToProps)(Search);
