// Libs
import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as yup from "yup";
import { withRouter } from "react-router";

// Actions
import { submitLogin, cleanAuthError } from "../module/login";

// Components
import { Input, Button } from "../designSystem";

// Assets
import warning from "../assets/images/warning.svg";
import "./Login.scss";

const Login = ({ submitLogin, isFetching, submitError, cleanAuthError }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [formError, setFormError] = useState("");

  const getInputValue = e => {
    return e.target.value;
  };

  const onKeyDown = e => {
    if (e.key === "Enter") {
      validateBeforeSubmit();
    }
  };

  let schema = yup.object().shape({
    email: yup
      .string()
      .required("No email provided.")
      .email("Please enter a valid email."),
    password: yup
      .string()
      .required("No password provided.")
      .min(8, "Password is too short - should be 8 chars minimum.")
  });

  const validateBeforeSubmit = () => {
    schema
      .validate({ email, password })
      .then(() => submitLogin({ email, password }))
      .catch(err => setFormError(err.errors));
  };

  return (
    <main className="Login__container">
      <div className="Login__form-container">
        <h1>C.Que</h1>
        <div className="Login__form">
          <Input
            type="email"
            placeholder="Email"
            onChange={e => setEmail(getInputValue(e))}
            onKeyDown={e => onKeyDown(e)}
            required
          />
          <Input
            type="password"
            placeholder="Password"
            onChange={e => setPassword(getInputValue(e))}
            onKeyDown={e => onKeyDown(e)}
            required
          />
          <Button 
            onClick={() => {
              cleanAuthError()
              setFormError("")
              validateBeforeSubmit()
            }} 
            disabled={isFetching}
          >
            Login
          </Button>
          <div className="Login__form-error">
            {formError && (
              <span>
                <img src={warning} alt="warning" />
                {formError}
              </span>
            )}
            {submitError && (
              <span>
                <img src={warning} alt="warning" />
                {submitError.message}
              </span>
            )}
          </div>
        </div>
      </div>
    </main>
  );
};

function mapStateToProps(state) {
  return { isFetching: state.auth.isFetching, submitError: state.auth.error };
}

function mapDispatchToProps(dispatch, ownProps) {
  return bindActionCreators(
    {
      submitLogin,
      cleanAuthError
    },
    dispatch
  );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
