// Libs
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import { useQueryParams, NumberParam, StringParam } from "use-query-params";
import DatePicker from "react-date-picker";
import useClippy from "use-clippy";

// Components
import Checkbox from "../components/CheckBox";
import Arrow from "../components/Arrow";
import SelectDropdown from "../components/Select";
import { Tabs } from "../designSystem";
import Search from "./collectorDashboard/Search";
import SearchHistory from "./collectorDashboard/search/SearchHistory";
import Tile from "./collectorDashboard/Tile";
import Breadcrumb from "./collectorDashboard/Breadcrumb";
import Modal from "./collectorDashboard/Modal";

// Actions
import { getAccounts } from "../module/accounts";
import setReviewValue from "../module/accounts/review";
import { setStatus } from "../module/accounts/statuses";
import { openConnectApp } from "../module/connectAPI";

// Utils
import { formatAccountNumber, formatMegasysNumber } from "../utils/string";
import {
  formatUnixDate,
  transformUnixDate,
  transformUnixtoString
} from "../utils/date";

// Static Data
import { staticStatuses } from "./collectorDashboard/staticStatuses";
import { staticBuckets } from "./collectorDashboard/staticBuckets";

// Style
import "./CollectorDashboard.scss";

import edit from "../assets/images/edit.svg";

const CollectorDashboard = ({
  openConnectApp,
  getAccounts,
  setStatus,
  accounts,
  pageCount,
  tiles
}) => {
  // Managing state in URL query parameters with serialization
  const [query, setQuery] = useQueryParams({
    page: NumberParam,
    sort: StringParam,
    order: StringParam,
    limit: NumberParam,
    bucket: StringParam,
    search: StringParam,
    company: StringParam,
    department: StringParam,
    branch: StringParam,
    team: StringParam,
    member: StringParam
  });

  // States
  const [listAccounts, setListAccounts] = useState([]);
  const [statusAccounts, setStatusAccounts] = useState([]);
  const [activeAccount, setActiveAccount] = useState([]);
  const [activeData, setActiveData] = useState(new Date());
  const [statusChanged, setStatusChanged] = useState();
  const [reviewChanged, setReviewChanged] = useState({});
  const [openModal, setOpenModal] = useState(false);

  // Custom Hooks
  // eslint-disable-next-line
  const [clipboard, setClipboard] = useClippy();

  // Setting the default query strings
  useEffect(() => {
    const { order, page, sort, limit, bucket } = query;

    if (!(order && page && sort && limit && bucket)) {
      setQuery({
        order: "asc",
        page: 1,
        sort: "name",
        limit: 25,
        bucket: "all"
      });
    }
  }, [query, setQuery]);

  // Triggering the getAccounts request every time the query string changes
  useEffect(() => {
    getAccounts({
      ...query
    });
  }, [query, getAccounts]);

  useEffect(() => {
    let status = [];
    let review = {};

    accounts.map(item => {
      review[item.id] = item.review;
      return (status[item.id] = obJectStatus(item.status.status));
    });

    setListAccounts(accounts);
    setStatusAccounts(status);
    setReviewChanged(review);
  }, [accounts]);

  const formatDate = date => {
    return moment(date).utc().format("MM/DD/YY");
  };

  const getOrder = () => {
    if (query.order === "asc") {
      return "desc";
    }
    if (query.order === "desc") {
      return "asc";
    }
  };

  const renderSortableHeader = (title, fieldName) => {
    return (
      <th onClick={() => setQuery({ sort: fieldName, order: getOrder() })}>
        <div className="CollectorDashboard__header">
          {title}{" "}
          {query.sort === fieldName && <Arrow up={query.order === "desc"} />}
        </div>
      </th>
    );
  };

  const renderPagination = () => {
    const setPage = pageValue => {
      const page = pageValue.selected + 1;
      setQuery({ page });
      getAccounts(page);
    };

    return (
      <ReactPaginate
        pageCount={Number(pageCount)}
        // Index 0 is page 1
        forcePage={query.page - 1}
        marginPagesDisplayed={1}
        pageRangeDisplayed={3}
        onPageChange={e => setPage(e)}
        containerClassName={"pagination"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"}
      />
    );
  };

  const obJectStatus = status => {
    return staticStatuses.filter(option => option.value === status)[0];
  };

  const changeStatusAccount = (status, item, index) => {
    setStatusChanged(status);
    setActiveData(new Date());
    setActiveAccount([index, item]);

    let statusVerifided = ["scheduled_payment", "promise_to_pay"].includes(
      status.value
    );

    if (statusVerifided) {
      setOpenModal(!openModal);
    } else {
      setStatusAccounts({
        ...statusAccounts,
        [item.id]: status
      });

      setStatus({
        status: status.value,
        megasysFullAccountNumber: item.megasysFullAccountNumber,
        accountId: item.id,
      });
      setReviewChanged({ ...reviewChanged, [item.id]: true });
      
      //item.id && 
      //setReviewValue(item.id, true);
      
      listAccounts[index] = {
        ...listAccounts[index],
        status: {
          id: item.id,
          tatus: status.value,
          promiseToPayDate: null
        }
      };

      setListAccounts(listAccounts);
    }
  };

  const cancelChangeStatus = () => {
    setOpenModal(!openModal);
  };

  const confirmChangeStatus = () => {
    setStatus({
      status: statusChanged.value,
      megasysFullAccountNumber: activeAccount[1].megasysFullAccountNumber,
      accountId: activeAccount[1].id,
      promiseToPayDate: activeData,
    });

    setStatusAccounts({
      ...statusAccounts,
      [activeAccount[1].id]: statusChanged
    });

    listAccounts[activeAccount[0]] = {
      ...listAccounts[activeAccount[0]],
      status: {
        id: activeAccount[1].id,
        status: statusChanged.value,
        promiseToPayDate: transformUnixDate(activeData)
      }
    };

    setListAccounts(listAccounts);
    setReviewChanged({ ...reviewChanged, [activeAccount[1].id]: true });
    setOpenModal(!openModal);
  };

  const changePromiseDate = (item, index) => {
    setActiveData(transformUnixtoString(item.status.promiseToPayDate));
    setStatusChanged(statusAccounts[item.id]);
    setActiveAccount([index, item]);
    setOpenModal(!openModal);
  };

  return (
    <>
      <Search onClick={searchValue => setQuery({ search: searchValue })} />

      <Breadcrumb />

      <Modal
        show={openModal}
        handleClose={() => {
          cancelChangeStatus();
        }}
        handleConfirm={() => {
          confirmChangeStatus();
        }}
      >
        <DatePicker
          minDate={new Date()}
          clearIcon={null}
          required={true}
          onChange={date => {
            setActiveData(date);
          }}
          value={activeData}
        />
      </Modal>

      <div className="CollectorDashboard__table">
        <div className="CollectorDashboard__bucket">
          <Tabs>
            {staticBuckets.map(staticBucket => {
              return (
                <Tabs.Tab
                  key={staticBucket.value}
                  active={query.bucket === staticBucket.value}
                  onClick={() =>
                    setQuery({ page: 1, bucket: staticBucket.value })
                  }
                >
                  {staticBucket.name}
                </Tabs.Tab>
              );
            })}
          </Tabs>
          <SearchHistory onClick={useQueryParams} />
        </div>
        {tiles.length > 0 && (
          <div className="CollectorDashboard__tiles">
            {tiles.map((tile) => {
              return <Tile info={tile}  key={tile.key}/>;
            })}
          </div>
        )}
        <table>
          <thead>
            <tr>
              <th></th>
              <th>Account</th>
              <th>AStatus</th>
              {renderSortableHeader("Name", "name")}
              {renderSortableHeader("Status", "statusStatus")}
              {renderSortableHeader("Balance", "balance")}
              <th>Promise</th>
              {renderSortableHeader("Next Due ", "nextDueDate")}
              {renderSortableHeader("Last Paid", "lastPayment")}
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {listAccounts.map((item, index) => {
              return (                
                <tr key={item.id} style={{backgroundColor: reviewChanged[item.id] === true ? '#f8f8f8' : '#fff' }}>
                  <td>
                    <div className="CollectorDashboard__account">
                      <span
                        className="CollectorDashboard__status-icon"
                        style={{
                          backgroundColor: statusAccounts[item.id]?.color
                        }}
                      />
                      <Checkbox
                        checked={reviewChanged[item.id]}
                        onChange={e => {
                          setReviewChanged({
                            ...reviewChanged,
                            [item.id]: e.target.checked
                          });

                          item.id &&
                            setReviewValue(item.id, e.target.checked);
                        }}
                      />
                    </div>
                  </td>
                  <td>
                    {formatAccountNumber(item.megasysFullAccountNumber)}
                  </td>
                  <td>{item.megasysAdminStatus}</td>
                  <td>
                    <div className="CollectorDashboard__name">
                      {item.name}
                    </div>
                  </td>
                  <td>
                    <SelectDropdown
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          border: `4px solid ${
                            statusAccounts[item.id]?.color
                          } !important`
                        })
                      }}
                      defaultValue={statusAccounts[item.id]}
                      value={statusAccounts[item.id]}
                      options={staticStatuses}
                      placeholder="Select Status"
                      width="213px"
                      onChange={value => {
                        changeStatusAccount(value, item, index);
                      }}
                    />
                  </td>
                  <td>{item.balance}</td>
                  <td>
                    {["scheduled_payment", "promise_to_pay"].includes(
                      statusAccounts[item.id].value
                    ) ? (
                      <>
                        {
                          <div className="promise__date">
                            {item.status.promiseToPayDate
                              ? formatUnixDate(item.status.promiseToPayDate)
                              : "--/--/----"}
                            <img
                              src={edit}
                              alt="editIcon"
                              onClick={() => {
                                changePromiseDate(item, index);
                              }}
                            />
                          </div>
                        }
                      </>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td>{formatDate(item.nextDueDate)}</td>
                  <td>{formatDate(item.lastPayment)}</td>
                  <td>
                    <div className="CollectorDashboard__actions">
                      {/* eslint-disable-next-line */}
                      <a
                        rel="noopener noreferrer"
                        onClick={() => {
                          return setClipboard(
                            `${formatMegasysNumber(
                              item.megasysFullAccountNumber
                            )}`
                          );
                        }}
                      >
                        Megasys
                      </a>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`https://payment.corsairapp.com/accounts/${item.megasysFullAccountNumber}`}
                      >
                        Pay
                      </a>
                      {/* eslint-disable-next-line */}
                      <a
                        rel="noopener noreferrer"
                        onClick={() => {
                          openConnectApp(item.megasysFullAccountNumber);
                        }}
                      >
                        Connect
                      </a>
                    </div>
                  </td>
                </tr>                
              );
            })}
          </tbody>
        </table>
        {renderPagination()}
      </div>
    </>
  );
};

function mapStateToProps(state) {
  const {
    isFetching,
    information: { accounts, pageCount }
  } = state.account;

  return {
    isFetching,
    accounts,
    pageCount,
    tiles: state.tiles.information || []
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { getAccounts, setStatus, openConnectApp },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CollectorDashboard);
