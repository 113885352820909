export const formatAccountNumber = (accountNumber) => {
  if (accountNumber.length === 9) {
    let splitted = accountNumber.split("");

    return `${splitted.slice(0, 3).join("")}-${splitted.slice(3).join("")}`;
  }
};

export const formatMegasysNumber = (accountNumber) => {
  if (accountNumber.length === 9) {
    let splitted = accountNumber.split("");

    return `collq${splitted.slice(0, 3).join("")}\t${splitted.slice(3).join("")}`;
  }
};
