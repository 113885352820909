import axios from "axios";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { baseAPI, headers } from "./baseAPI";
import { setHistory } from "./UI/search";

const initialState = {
  isFetching: false,
  information: {
    accounts: [],
    itemCount: 0,
    totalItems: "",
    pageCount: "",
    next: "",
    previous: "",
  },
};

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "accounts.isFetching":
      return { ...state, isFetching: true };

    case "accounts.fetchSuccess":
      return { ...state, isFetching: false, information: action.response };

    case "accounts.fetchError":
      return { ...state, isFetching: false, error: action.response };

    default:
      return state;
  }
}

// Actions

export function getAccounts({
  order,
  page,
  sort,
  limit,
  bucket,
  search = "",
  company = "",
  department = "",
  branch = "",
  team = "",
  member = "",
}) {
  return async dispatch => {
    if (order && page && sort) {
      dispatch({ type: "accounts.isFetching" });
      dispatch(showLoading());

      const URL = `${baseAPI}/accounts?page=${page}&limit=${limit}&order=${order.toUpperCase()}&sort=${sort}&bucket=${bucket}&search=${search}&company=${company}&department=${department}&branch=${branch}&team=${team}&member=${member}`;

      try {
        const response = await axios({
          method: "get",
          url: URL,
          headers,
        });

        dispatch({
          type: "accounts.fetchSuccess",
          response: response.data,
        });

        search &&
          dispatch(setHistory({ historyValue: window.location.search }));
        dispatch(hideLoading());
      } catch (error) {
        if (error?.response?.status === 401) {
          localStorage.removeItem("accessToken");
          window.location.replace("/login");
        }
        dispatch({ type: "accounts.fetchError", error });
        // statusCode
        dispatch(hideLoading());
      }
    }
  };
}
