// Libs
import React from "react";
import PropTypes from "prop-types";

// Styles
import "./Button.scss";

const ButtonThemes = {
  primary: "Button__primary",
  secondary: "Button__secondary ",
};

export const Button = ({
  children,
  onClick,
  type,
  disabled,
  theme,
  selected,
}) => {
  return (
    <button
      className={`Button__container ${ButtonThemes[theme]} ${
        selected ? "Button__selected" : ""
      }`}
      onClick={() => onClick()}
      disabled={disabled}
      type={type}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  type: PropTypes.string,
  theme: PropTypes.string,
};

Button.defaultProps = {
  theme: "primary",
};

export default Button;
