import axios from "axios";
import { showLoading, hideLoading } from "react-redux-loading-bar";

// Reducer
export default function reducer(state = {}, action) {
  switch (action.type) {
    case "connectAPI.isFetching":
      return { ...state, isFetching: true };

    case "connectAPI.fetchSuccess":
      return {
        ...state,
        ...action.response,
      };

    case "connectAPI.fetchError":
      return { ...state, isFetching: false, error: action.response };

    default:
      return state;
  }
}

// Actions
export function openConnectApp(megasysAccountId) {
  return async dispatch => {
    dispatch(showLoading());

    try {
      const response = await axios({
        method: "get",
        url: `https://c.cfsnow.com/rest/507/hzeth1b2pctfjqtb/crm.deal.list.json?select[ID]&filter[TITLE]=${megasysAccountId}`,
      });

      dispatch({
        type: "connectAPI.fetchSuccess",
        response,
      });

      window.open(
        `https://c.cfsnow.com/crm/deal/details/${response.data.result[0].ID}/`,
        "_blank"
      );

      dispatch(hideLoading());
    } catch (error) {
      dispatch(hideLoading());
    }
  };
}
