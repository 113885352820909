// Libs
import React, { useEffect } from "react";
import { useQueryParams, StringParam } from "use-query-params";
import { connect } from "react-redux";
import moment from "moment";
import { bindActionCreators } from "redux";

// Style
import "./Breadcrumb.scss";

import { getCurrentUser } from "../../module/currentUser";

const Breadcrumb = ({ menu, getCurrentUser, currentUser }) => {
  const [query] = useQueryParams({
    company: StringParam,
    branch: StringParam,
    team: StringParam,
    member: StringParam,
    department: StringParam,
  });


  useEffect(() => {
    getCurrentUser();
  }, [getCurrentUser]);

  const getMemberName = value => {
    if (query[value] !== undefined) {
      let object = getObjects(menu, "key", query[value])
      
      let objectName = object[0]?.label;

      if (object[0]?.paramId !== 'company'){
        objectName = " > " + objectName 
      }

      return objectName
    } else {
      return currentUser && `${currentUser.lastName}, ${currentUser.firstName}`;
    }
  };

  const getObjects = (obj, key, val) => {
    let objects = [];
    for (var i in obj) {
      if (!obj.hasOwnProperty(i)) continue;
      if (typeof obj[i] === "object") {
        objects = objects.concat(getObjects(obj[i], key, val));
      } else if (i === key && obj[key] === val) {
        objects.push(obj);
      }
    }
    return objects;
  };

  return (
    <div className="Breadcrumb__container">
      <div>
        {!query.company &&
          !query.branch &&
          !query.team &&
          !query.member &&
          !query.department &&
          getMemberName()}
        {query.company && getMemberName("company")}
        {query.department && getMemberName("department")}
        {query.branch && getMemberName("branch")}
        {query.team && getMemberName("team")}
        {query.member && getMemberName("member")}
      </div>
      <div>{moment(new Date()).format("MMMM YYYY")}</div>
    </div>
  );
};

function mapStateToProps(state) {
  return { menu: state.menu.data, currentUser: state.currentUser.data };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getCurrentUser }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(Breadcrumb);
