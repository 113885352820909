import React from "react";

// Style
import "./Arrow.scss";

const Arrow = ({ up }) => {
  return (
    <span className={`Arrow ${up ? "Arrow__open" : ""}`}>
      <span className="Arrow__left-bar"></span>
      <span className="Arrow__right-bar"></span>
    </span>
  );
};

export default Arrow;
