export const staticStatuses = [
  { value: "no_status", label: "No Status", color: "white" },
  { value: "payment_received", label: "Payment Received", color: "#B5E6B4" },
  { value: "payment_received_pnm", label: "Payment Received PNM", color: "#B5E6B4" },
  
  { value: "promise_to_pay", label: "Promise to Pay", color: "#F1D190" },  
  { value: "scheduled_payment", label: "Scheduled Payment", color: "#F1D190" },
  
  { value: "reversal", label: "Reversal", color: "#AF93C6" },
  { value: "payment_declined_pnm", label: "Payment Declined PNM", color: "#AF93C6" },
  { value: "delayed_posting", label: "Delayed Posting", color: "#AF93C6" },
  { value: "short_payment", label: "Short Payment", color: "#AF93C6" },
  { value: "broken_promise", label: "Broken Promise", color: "#AF93C6" },
  
  { value: "repossessed", label: "Repossessed", color: "#C65A79" },
  { value: "insurance_deficient", label: "Insurance Deficient", color: "#C65A79"},
  { value: "abandoned_vehicle", label: "Abandoned Vehicle", color: "#C65A79" },
  { value: 'pending_buyback', label: "Pending Buyback", color: "#C65A79"},
  { value: 'impound', label: "Impound", color: "#C65A79"},
  { value: 'active_recourse', label: "Active Recourse", color: "#C65A79"},
  { value: 'insurance_claim', label: "Insurance Claim", color: "#C65A79"},
  { value: 'settlement', label: "Settlement", color: "#C65A79"},
  { value: 'bankruptcy', label: "Bankruptcy", color: "#C65A79"},
];
