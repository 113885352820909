import React from "react";
import Select, { components } from "react-select";
import PropTypes from "prop-types";

import "./Select.scss";

const Option = props => {
  return (
    <div style={{ backgroundColor: props.data.color }}>
      <components.Option {...props} />
    </div>
  );
};

const SelectDropdown = props => {
  return (
    <Select {...props} classNamePrefix="react-select" components={{ Option }} />
  );
};

SelectDropdown.propTypes = {
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
};

export default SelectDropdown;
