import axios from "axios";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { baseAPI, headers } from "./baseAPI";

const initialState = { isFetching: false };

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "currentUser.isFetching":
      return { ...state, isFetching: true };

    case "currentUser.fetchSuccess":
      return {
        ...state,
        isFetching: false,
        ...action.response,
      };

    case "currentUser.fetchError":
      return { ...state, isFetching: false, error: action.response };

    default:
      return state;
  }
}

// Actions
export function getCurrentUser() {
  return async (dispatch) => {
    dispatch({ type: "user.isFetching" });
    dispatch(showLoading());

    try {
      const response = await axios({
        method: "get",
        url: `${baseAPI}/user`,
        headers,
      });

      dispatch({
        type: "currentUser.fetchSuccess",
        response,
      });

      dispatch(hideLoading());
    } catch (error) {
      dispatch({ type: "currentUser.fetchError", error });
      // statusCode
      dispatch(hideLoading());
    }
  };
}
