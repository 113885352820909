// Libs
import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import TreeMenu, { ItemComponent } from "react-simple-tree-menu";
import { useQueryParams, StringParam } from "use-query-params";
import { Resizable } from "re-resizable";

// Actions
import { getMenu, activeMenu } from "../module/menu";
import { getTiles } from "../module/tiles";

// Utils

// Style
import "./Navigation.scss";

import leftArrow from "../assets/images/left-arrow.svg";
import rightArrow from "../assets/images/right-arrow.svg";

const Navigation = ({
  children,
  getMenu,
  menu,
  active,
  getTiles,
  activeMenu,
  currentUser,
  refreshStatus,
}) => {
  const [query, setQuery] = useQueryParams({
    company: StringParam,
    branch: StringParam,
    department: StringParam,
    team: StringParam,
    member: StringParam,
    bucket: StringParam,
  });

  const [menuItemParamId, setMenuItemParamId] = useState("");
  const [menuItemKey, setMenuItemKey] = useState("");
  const [widthSidebar, setWidthSidebar] = useState(300);
  const [opened, setOpened] = useState(true);
  const [activeNode, setActiveNode] = useState(
    () => {
      const currentMenu = localStorage.getItem('QUERY_CONTEXT_LOCALSTORAGE');

      if(currentMenu){
        return JSON.parse(currentMenu)
      }else{
        return "myQueue"
      }
    }
  );
  const treeMenu = useRef();


  const getTilesByUrl = () => {
    const {company, branch, department, team, member } = query;
    if (member) {
      return getObjects(menu, "key", member)[0]; 
    } else if (team) {
      return getObjects(menu, "key", team)[0];
    } else if (department) {
      return getObjects(menu, "key", department)[0];  
    } else if (branch) {
      return getObjects(menu, "key", branch)[0];
    } else if (company) {
      return getObjects(menu, "key", company)[0];
    } else {
      return { key: "", paramId: "" };
    }
  };

  useEffect(() => {
    getMenu(); 
  }, [getMenu]);

  //Runs when Current User or page refresh
  useEffect(() => {
    const { company, branch, department, team, member, bucket } = query;
    //only current users
    if (!company && !branch && !department && !team && !member) {
      getTiles({
        menuItemParamId: "member",
        menuItemKey: "",
        bucket: bucket,
        company: query.company || "",
        department: query.department || "",
        team: query.team || "",
        member: query.member || ""
      });
    } 

  }, [query, getTiles, query.bucket]);

  //Runs when something is picked from left menu and page refresh
  useEffect(
    () => {
      if (menuItemParamId || getTilesByUrl()?.paramId) {
        getTiles({
          menuItemParamId: menuItemParamId || getTilesByUrl()?.paramId,
          menuItemKey: menuItemKey || getTilesByUrl()?.key,
          bucket: query.bucket,
          company: query.company || "",
          department: query.department || "",
          team: query.team || "",
          member: query.member || ""
        });
      }
    },
    // eslint-disable-next-line
    [query.bucket, menu, menuItemParamId, menuItemKey, getTiles]
  );

  // Runs when page is reloaded from dropdown
  useEffect(
    () => {
      if (refreshStatus > 0) {
        getTiles({
          menuItemParamId: getTilesByUrl().paramId || "member",
          menuItemKey: getTilesByUrl().key || "",
          bucket: query.bucket,
          company: query.company || "",
          department: query.department || "",
          team: query.team || "",
          member: query.member || ""
        });
      }
    },

    // eslint-disable-next-line
    [getTiles, query.bucket, refreshStatus]
  );

  const getObjects = (obj, key, val) => {
    let objects = [];
    for (let i in obj) {
      if (!obj.hasOwnProperty(i)) continue;
      if (typeof obj[i] === "object") {
        objects = objects.concat(getObjects(obj[i], key, val));
      } else if (i === key && obj[key] === val) {
        objects.push(obj);
      }
    }
    //console.log("Obj COncat:", objects)
    return objects;
  };

  const signOut = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("QUERY_CONTEXT_LOCALSTORAGE");
    window.location.replace("/login");
  };

  const searchByQueryString = e => {
    // console.log('searchByQueryString', e)
    const paramId = e.paramId;
    let value;
    let parentIdArray = [];
    if (paramId !== 'company') {
      parentIdArray = e.key.split("/");
      parentIdArray.reverse()
      value = (parentIdArray.slice(0, parentIdArray.length))[0];
    } else {
      value = e.key;
    }
    // console.log('value', value);
    // console.log(parentIdArray);
    // console.log('ParamID',paramId);
    // const value = ary.pop();
    // const parents = e.replace(value, '');``
    setMenuItemKey(value);
    setMenuItemParamId(paramId);

    if (paramId === "company") {
      setQuery({
        [paramId]: value,
        department: "",
        team: "",
        member: "",
        branch: "",
      });
      getTiles({
        menuItemParamId: paramId,
        menuItemKey: value,
        bucket: query.bucket,
        company: value,
        department: "",
        team: "",
        member: "",
      });
    } else if (paramId === "department") {
      setQuery({
        company: parentIdArray[1],
        [paramId]: value,
        team: "",
        member: "",
        branch: "",
      });
      getTiles({
        menuItemParamId: paramId,
        menuItemKey: value,
        bucket: query.bucket,
        company: parentIdArray[1],
        department: value,
        team: "",
        member: "",
      });
    } else if (paramId === "branch") {
      setQuery({
        [paramId]: value,
        company: parentIdArray[0],
        team: "",
        member: "",
        department: "",
      });
    } else if (paramId === "team") {
      setQuery({
        company: parentIdArray[2],
        department: parentIdArray[1],
        [paramId]: value,
        member: "",
        branch: "",
      });
      getTiles({
        menuItemParamId: paramId,
        menuItemKey: value,
        bucket: query.bucket,
        company: parentIdArray[2],
        department: parentIdArray[1],
        team: value,
        member: "",
      });
    } else if (paramId === "member") {
      setQuery({
        company: parentIdArray[3],
        department: parentIdArray[2],
        team: parentIdArray[1],
        [paramId]: value,
        branch: "",
      });
      getTiles({
        menuItemParamId: paramId,
        menuItemKey: value,
        bucket: query.bucket,
        company: parentIdArray[3],
        department: parentIdArray[2],
        team: parentIdArray[1],
        member: value,
      });
    }
  };

  const sidebarOpened = () => {
    if (opened) {
      setWidthSidebar(30);
    } else {
      setWidthSidebar(300);
    }
    setOpened(!opened);
  };

  return (
    <div className="sidebar__content">
      <Resizable
        className="sidebar__resized"
        style={{ display: "flex" }}
        size={{ width: widthSidebar, height: "100vh" }}
        onResizeStop={(e, direction, ref, d) => {
          setWidthSidebar(widthSidebar + d);
        }}
        minWidth={30}
        maxWidth={300}
        enable={{ right: true }}
      >
        <button
          className="toggle-sidebar"
          type="button"
          onClick={() => sidebarOpened()}
        >
          <img src={opened ? leftArrow : rightArrow} alt="arrow" />
        </button>
        <div className="sidebar">
          <div className="avatar">
            {/* <img src="https://i.pravatar.cc/300" alt="avatar" /> */}
            <div className="sidebar__username">
              <div>
                {currentUser &&
                  `${currentUser.lastName}, ${currentUser.firstName}`}
              </div>
              {/* <span>Edit</span> */}
            </div>
          </div>

          <div className="sidebar__body">
            <TreeMenu
              disableKeyboard={false}
              hasSearch={false}
              data={menu}
              resetOpenNodesOnDataUpdate={false}
              activeKey={activeNode}
              initialActiveKey={activeNode}
              ref={treeMenu}
              initialOpenNodes={activeNode}
            >
              {({ items }) => (
                <ul>
                  {items.map(item => {
                    return (
                      <ItemComponent
                        {...item}
                        activeNode={activeNode}
                        onClick={() => {
                          activeMenu(item.key);
                          setActiveNode(item.key);
                          item.key === "myQueue" &&
                            window.location.replace("/collector");
                          searchByQueryString(item);
                        }}
                      />
                    );
                  })}
                </ul>
              )}              
            </TreeMenu>
            <div className="sidebar__signOut" onClick={() => signOut()}>
              SIGN OUT
            </div>
          </div>
        </div>
      </Resizable>
      <div className="content">{children}</div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    menu: state.menu.data,
    currentUser: state.currentUser.data,
    refreshStatus: state.statuses.refreshStatus,
    active: state.menu.activeMenu
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getMenu, getTiles, activeMenu}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
