import { queryStringToObject } from "../../utils/url";

const initialState = {
  history: [],
};

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "search.setHistory":
      return {
        ...state,
        history: removeDuplicateSearch([...state.history, action.historyValue]),
      };

    case "search.removeHistory":
      return {
        ...state,
        history: state.history.filter(el => el.search !== action.value),
      };

    case "search.removeAllHistory":
      return {
        ...state,
        history: [],
      };

    default:
      return state;
  }
}

// Actions
export function setHistory({ historyValue }) {
  const objectParam = queryStringToObject(historyValue);
  return dispatch => {
    if (objectParam.search) {
      dispatch({
        type: "search.setHistory",
        historyValue: objectParam,
      });
    }
  };
}

export function removeHistory(value) {
  return dispatch => {
    dispatch({
      type: "search.removeHistory",
      value,
    });
  };
}

export function removeAllHistory() {
  return dispatch => {
    dispatch({
      type: "search.removeAllHistory",
    });
  };
}

// private

function removeDuplicateSearch(arr) {
  return [...new Map(arr.map(item => [item.search, item])).values()];
}
