import { combineReducers } from "redux";
import { loadingBarReducer } from "react-redux-loading-bar";

import auth from "../module/login";
import account from "../module/accounts";
import statuses from "../module/accounts/statuses";
import search from "../module/UI/search";
import menu from "../module/menu";
import tiles from "../module/tiles";
import currentUser from "../module/currentUser";
import connectAPI from "../module/connectAPI";

const rootReducer = combineReducers({
  auth,
  account,
  statuses,
  search,
  menu,
  tiles,
  currentUser,
  connectAPI,
  loadingBar: loadingBarReducer,
});

export default rootReducer;
