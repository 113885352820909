import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

// Components
import Navigation from "../components/Navigation";
import ProtectedRoute from "../components/ProtectedRoute";
// Pages
import Login from "../pages/Login";
import CollectorDashboard from "../pages/CollectorDashboard";

const NestedRoutes = () => {
  return (
    <Switch>
      <Route exact path="/">
        <Redirect to="/login" />
      </Route>
      <Route path={`/login`} component={Login} />
      <ProtectedRoute path="/">
        <Navigation>
          <Route path={`/collector`} component={CollectorDashboard}></Route>
        </Navigation>
      </ProtectedRoute>
    </Switch>
  );
};

export default NestedRoutes;
