// Libs
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { removeHistory } from "../../../module/UI/search";
import { useQueryParams, StringParam } from "use-query-params";

// Components
import { Button } from "../../../designSystem";

// Style
import "./SearchHistory.scss";
const SearchHistory = ({ history, removeHistory }) => {
  const [query, setQuery] = useQueryParams({
    search: StringParam,
  });
  return (
    <div className="SearchHistory__container">
      
      {history.map((queryStrings) => {
        if (queryStrings.search) {
          return (
            <div className="SearchHistory__search">
              <Button
                theme="secondary"
                selected={query.search === queryStrings.search}
                onClick={() => setQuery(queryStrings)}
              >
                Search:{" "}
                <span className="SearchHistory__value">
                  {queryStrings.search}
                </span>{" "}
              </Button>
              <span
                className="SearchHistory__cross"
                onClick={() => {
                  setQuery({ search: "" });
                  removeHistory(queryStrings.search);
                }}
              >
                ×
              </span>
            </div>
          );
        }
        /*eslint array-callback-return: ["error", { allowImplicit: true }]*/
        return;
      })}
    </div>
  );
};

function mapStateToProps(state) {
  return { history: state.search.history };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ removeHistory }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchHistory);
