// Libs
import React from "react";
import PropTypes from "prop-types";

// Styles
import "./Input.scss";

const inputThemes = {
  primary: "Input__primary",
  secondary: "Input__secondary"
};

export const Input = ({
  placeholder,
  onKeyDown,
  required,
  onChange,
  label,
  theme,
  type,
  value
}) => {
  return (
    <div className={`Input__container ${inputThemes[theme]}`}>
      {label && <label>{label}</label>}
      <input
        placeholder={placeholder}
        onKeyDown={onKeyDown}
        required={required}
        onChange={onChange}
        value={value}
        type={type}
      />
    </div>
  );
};

Input.propTypes = {
  placeholder: PropTypes.string,
  onKeyDown: PropTypes.func,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  themes: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.any
};

Input.defaultProps = {
  theme: "primary",
  required: false,
  type: "text"
};

export default Input;
