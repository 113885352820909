import React from 'react';

import "./Modal.scss";

const Modal = ({ id = "modal", handleClose = () => {}, handleConfirm = () => {}, show, children }) => {

  const handleOutsideClick = (e) => {
    if(e.target.id === id){
      handleClose()
    }
  }

  const showHideClassName = show ? "modal display-block" : "modal display-none";

  return (
    <div id={id} className={showHideClassName} onClick={handleOutsideClick}>
      <section className="modal-container">
        <div className="modal-header">
          <span onClick={handleClose}>x</span>
        </div>
        <div className="modal-body">
          {children}
        </div>
        <div className="modal-footer">
          <button className="confirm-buttom"onClick={handleConfirm}>confirm</button>
          <button className="cancel-buttom"onClick={handleClose}>cancel</button>
        </div>
      </section>
    </div>
  );
};

export default Modal;
