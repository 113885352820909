// Libs
import React from "react";

// Style
import "./Tab.scss";

const Tab = ({ children, active, onClick }) => {
  return (
    <div
      onClick={() => onClick()}
      className={`Tab__container ${active ? "Tab__active" : ""}`}
    >
      {children}
    </div>
  );
};

Tab.Tab = Tab;

export default Tab;
