import axios from "axios";
import { baseAPI, headers } from "./baseAPI";
import { showLoading, hideLoading } from "react-redux-loading-bar";

const initialState = {
  isFetching: false,
  information: [],
};

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "tiles.isFetching":
      return { ...state, isFetching: true };

    case "tiles.fetchSuccess":
      return { ...state, isFetching: false, information: action.response };

    case "tiles.fetchError":
      return {
        ...state,
        isFetching: false,
        error: action.response,
        information: [],
      };

    default:
      return state;
  }
}

// Actions
export function getTiles({
  menuItemParamId = "",
  menuItemKey = "",
  company = "",
  department = "",
  team = "",
  member = "",
  bucket = "all",
}) {
  return async dispatch => {
    dispatch({ type: "tiles.isFetching" });
    dispatch(showLoading());

    const URL = `${baseAPI}/tiles?group_type=${menuItemParamId}&group_id=${menuItemKey}&bucket=${bucket}&company=${company}&department=${department}&team=${team}&member=${member}`;

    try {
      const response = await axios({
        method: "get",
        url: URL,
        headers,
      });

      dispatch({
        type: "tiles.fetchSuccess",
        response: response.data,
      });
      dispatch(hideLoading());
    } catch (error) {
      dispatch({ type: "tiles.fetchError", error });
      dispatch(hideLoading());
    }
  };
}
