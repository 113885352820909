import moment from "moment";

export const formatUnixDate = (unixDate) => {
  return moment.unix(unixDate).format("MM/DD/YY");
};

export const transformUnixDate = (date) => {
  return moment(date).format("X");
};

export const transformUnixtoString = (unixDate) => {
  return new Date(unixDate * 1000);
};

export const getPreviousMonth = () => {
  return moment().subtract(1, "month").format("MMMM");
};
