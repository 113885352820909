const getAccessToken = () => localStorage.getItem("accessToken");

export const isAuthenticated = getAccessToken();

export const headers = {
  Authorization: `Bearer ${getAccessToken()}`,
  "Content-Type": "application/json"
};

const isLocalDev = process.env.REACT_APP_IS_LOCAL_DEV;
const envBaseApi =
  isLocalDev === "true"
    ? `http://localhost:5000`
    : process.env.REACT_APP_BASE_API;
export const baseAPI = envBaseApi;
