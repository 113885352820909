import axios from "axios";
import { baseAPI, headers } from "../baseAPI";
import { showLoading, hideLoading } from "react-redux-loading-bar";

const initialState = {
  isFetching: false,
  refreshStatus: 0,
};

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "statuses.isFetching":
      return { ...state, isFetching: true };

    case "statuses.fetchSuccess":
      return {
        ...state,
        isFetching: false,
        information: action.response,
        refreshStatus: action.refreshStatus,
      };

    case "statuses.fetchError":
      return { ...state, isFetching: false, error: action.response };

    default:
      return state;
  }
}

// Actions
export function setStatus({
  megasysFullAccountNumber,
  status,
  accountId,
  promiseToPayDate,
}) {
  return async dispatch => {
    if (megasysFullAccountNumber && status && accountId) {
      dispatch({ type: "statuses.isFetching" });
      dispatch(showLoading());

      const URL = `${baseAPI}/accounts/${accountId}/status`;

      try {
        const response = await axios.post(
          URL,
          {
            megasysFullAccountNumber,
            status,
            accountId,
            promiseToPayDate,
          },
          {
            headers,
          }
        );

        dispatch({
          type: "statuses.fetchSuccess",
          response: response.data,
          refreshStatus: Math.floor(Math.random() * 10000000),
        });
        dispatch(hideLoading());
      } catch (error) {
        if (error.response.status === 401) {
          localStorage.removeItem("accessToken");
          window.location.replace("/login");
        }
        dispatch({ type: "statuses.fetchError", error });
        dispatch(hideLoading());
      }
    }
  };
}
