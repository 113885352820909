// Libs
import React from "react";

// Components
import Tab from "./tabs/Tab";

// Style
import "./Tabs.scss";

export const Tabs = ({ children }) => {
  return <div className="Tabs__container">{children}</div>;
};

Tabs.Tab = Tab;

export default Tabs;
