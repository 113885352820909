// React
import React from "react";
import ReactDOM from "react-dom";

// Router
import { BrowserRouter as Router, Route } from "react-router-dom";
import Routes from "./Routes";

// Redux
import { Provider } from "react-redux";
import store from "./redux/store";

import LoadingBar from "react-redux-loading-bar";
import { QueryParamProvider } from "use-query-params";

import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
  <Provider store={store}>
    <LoadingBar className="LoadingBar" />
    <Router>
      <QueryParamProvider ReactRouterRoute={Route}>
        <Routes />
      </QueryParamProvider>
    </Router>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
