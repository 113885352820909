// Libs
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Actions
import { setHistory } from "../../module/UI/search";

// Style
import "./Tile.scss";

const colorByType = {
  dynamic_tile: "#2C75B3",
  payment_received: "#B5E6B4",
  promised: "#F1D190",
  alerts: "#AF93C6",
  deficiencies: "#C65A79",
};

const Tile = ({ info, activeBucket }) => {
  return (
    <div
      className="Tile__container"
      style={{ borderColor: colorByType[info.key], order: `${info.order}` }}
    >
      <div className="Tile__title">{info.label}</div>
      <div className="Tile__body">
        <div className="Tile__info">
          {info.key === "dynamic_tile" ? (
            info.label === "Uncollected" || info.label === "Active" ? (
              " "
            ) : (
              <div className="Tile__percentage">{info.percentage}%</div>
            )
          ) : (
            ""
          )}
          <div className="Tile__count">{info.count}</div>
        </div>
        <div className="Tile__sum">{info.sum || "$00,00.00"}</div>
      </div>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setHistory }, dispatch);
}

export default connect(null, mapDispatchToProps)(Tile);
