import axios from "axios";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { baseAPI, headers } from "./baseAPI";

const initialState = {
  isFetching: false,
  data: [
    {
      key: "myQueue",
      label: "My Queue",
      url: "",
    },
  ],
  activeMenu: null
};

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "menu.isFetching":
      return { ...state, isFetching: true };

    case "menu.fetchSuccess":
      return {
        ...state,
        isFetching: false,
        data: [...state.data, ...action.response],
      };

    case "menu.fetchError":
      return { ...state, isFetching: false, error: action.response };

    case "menu.active":
      return { ...state, activeMenu: action.response };

    default:
      return state;
  }
}

// Actions
export function getMenu() {
  return async (dispatch) => {
    dispatch({ type: "menu.isFetching" });
    dispatch(showLoading());

    try {
      const response = await axios({
        method: "get",
        url: `${baseAPI}/menu/list/side-menu`,
        headers,
      });

      dispatch({
        type: "menu.fetchSuccess",
        response: response.data,
      });

      dispatch(hideLoading());
    } catch (error) {
      dispatch({ type: "menu.fetchError", error });
      // statusCode
      dispatch(hideLoading());
    }
  };
}

export function activeMenu(key) {
  return (dispatch) => {
    localStorage.setItem('QUERY_CONTEXT_LOCALSTORAGE', JSON.stringify(key));
    dispatch({
      type: "menu.active",
      response: key,
    });
  }
}
