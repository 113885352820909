import axios from "axios";
import { baseAPI, headers } from "../baseAPI";

// Actions
export default function setReviewValue(accountId, review){  
 
  const URL = `${baseAPI}/accounts/${accountId}/review`;
  
  return axios.put(
    URL, {
      review,
    },
    {
      headers,
    }
  );
}
